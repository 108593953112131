import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import Helmet from "react-helmet"
import SEO from "../components/seo"

const DedicatedPage = () => (
  <div>
    <SEO 
        title="Dedicated Servers Hosting Packages" 
        description="Reliable hardware configurations. Superb uptime. A dedicated support staff. All the server power you need for 
        your ever–increasing web hosting requirements."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-title"><span className="block text-indigo-600 xl:inline">Dedicated Servers</span> Hosting Packages</p>
              <p className="section-after-title">
              Reliable hardware configurations. Superb uptime. A dedicated support staff. All the server power you need for 
              your ever–increasing web hosting requirements. Dedicated servers offered by Sheephostingbay at appealing 
              prices. Take advantage of cost–free dedicated IP, reseller web hosting bonuses and a 99.9% network 
              uptime with each of our servers. Pick an Operating System right at signup.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="rounded-md shadow">
                      <a href="https://sheephostingbay.duoservers.com/dedicated-brochure/" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                      Download Brochure (PDF)
                      </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a href="https://sheephostingbay.duoservers.com/dedicated-catalog/" rel="noreferrer" target="_blank" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      Get Catalog (PDF)
                      </a>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title m-4 p-4"><span className="block text-indigo-600 xl:inline">High-performance </span> Servers <span role="img" aria-label="emoji">✨ </span></p>
            </div>
            <Helmet>
            <link rel="stylesheet" href="https://comparetables.duoservers.com/plain.css?table_bkg=FFFFFF&table_color=4338CA&plan_names_bkg=EEEEEE&features_hover=F9F9F9&order_bkg=4338CA&order_color=FFFFFF"/>
            <script src="https://code.jquery.com/jquery-2.2.4.min.js" integrity="sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44=" crossorigin="anonymous"></script>
            <script src="https://comparetables.duoservers.com/script.js?store_id=290832&service=dedicated&style=plain&order_url=&order_text=Order&container_id=compareTable&features_type=1"></script>


            </Helmet>
            <div id="compareTable" className="overflow-x-scroll shadow-lg"></div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/centos-debian-ubuntu.webp" className="w-60" alt="CentOS, Debian, Ubuntu" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">CentOS, Debian, Ubuntu </p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> Using Sheephostingbay’s dedicated servers hosting services will 
                                    provide an extremely high degree of control over your server setup. Apart from the dedicated hosting server configuration, 
                                    you’ll as well be given the option to to decide an Linux OS. At the signup page, you’ll discover a pull–down selection 
                                    with Linux versions – Ubuntu, Debian or CentOS. All you need to do is let us know which OS you will need and we will 
                                    install it for you along with our free–of–charge, in–house developed Web Control Panel.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/free-web-hosting-control-panel-02.webp" className="w-60" alt="A Free Web Control Panel" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A Free Web Control Panel</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> Dealing with a dedicated hosting server could seem to be sophisticated, yet, using our 
                                    totally free Web Control Panel, handling your websites and the dedicated hosting server itself can be pretty trouble–free. You’ll actually feel 
                                    just like making proper use of a standard website hosting service, however with all of the capacity of your dedicated hosting server behind 
                                    your back. Moreover, you’ll obtain all the 100% free add–ons and specific tools that are available in the Web Control Panel.
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/data-center-03.webp" className="w-60" alt="A State–of–the–Art Data Center" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A State–of–the–Art Data Center</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> We have selected quite possibly the most dependable and secure USA datacenter to house your 
                                    dedicated hosting server. The Steadfast facility is located in the proximity of a communications portal, at the heart of Chicago, and offers a good 
                                    amount of system potential and ultra–fast connections to the East and West Coasts. Stability is warranted by an on–site supervising crew online 
                                    24/7/365. The chance of breakdowns due to earthquakes is greatly decreased too.
                                    </div>
                                </div>
                            </div>
                        </div>       

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/ssd-18.webp" className="w-60" alt="Dedicated Servers with SSD Drives" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Dedicated Servers with SSD Drives</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> The advantages of using solid-state drives should not be underrated when it comes to 
                                    dedicated servers. They offer dramatically enhanced access speeds and a greater I/O capacity, which implies faster loading websites. This also 
                                    implies that the server's central processing unit can handle more requests, which will discernibly improve its performance.
                                    <br/>
                                    This is exactly why the majority of our Intel-powered dedicated servers boast extremely fast & dependable SSD disks by default.
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/full-root-access.webp" className="w-60" alt="Full Root Access" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Full Root Access</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> A dedicated hosting server will give you total control and access to its hosting environment. 
                                    With every dedicated hosting server, we integrate full root access to the server, so you could employ it for a range of purposes. In particular, you may 
                                    manage different websites, operate all types of free web applications, build a gaming server and even initiate your individual hosting business. At the 
                                    same time, we offer you SSH server privileges to create secure server connections.
                                    </div>
                                </div>
                            </div>
                        </div>      

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/managed-services-02.webp" className="w-60" alt="Managed Services" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Managed Services</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> In case that you do not have server administration know–how, you are provided with the 
                                    option to hand all the tough tasks to our skilled administrators. Apart from the network surveillance duties, they’ll as well handle any 
                                    once–a–week Operating System upgrades, supervising & rebooting processes as well as troubleshooting processes. All these professional 
                                    services are integrated into our Administration Services offer, that on top of that includes as much as 50 GB of backup capacity.
                                    </div>
                                </div>
                            </div>
                        </div>  

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/installation-troubleshooting.webp" className="w-60" alt="Installation & troubleshooting" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Installation & troubleshooting</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> With each dedicated hosting server, you can reap the benefits of our 24 hours 
                                    tech assist. Nevertheless, there are some matters which demand more expert service. This is when you can take advantage of the 
                                    Installation & Troubleshooting solution to get our proficient system administrators have a look and address your difficulty, 
                                    or set up any solution or app of your choice. The Installation & Troubleshooting plan is available as a part of our 
                                    Administration Services deal as well as individually.
                                    </div>
                                </div>
                            </div>
                        </div>  

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/free-bonuses.webp" className="w-60" alt="Free Bonuses" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Free Bonuses</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> Our dedicated hosting servers have a great many free–of–charge bonuses to make you 
                                    manage your own server setup gratis. We give you a totally free Hepsia Web Control Panel featuring almost innumerable website hosting 
                                    alternatives, a drag–n–drop File Manager, a precise web stats tracking interface together with support for multiple languages. 
                                    Furthermore, you’ll obtain one 100% free dedicated IP address, and even a domain name channel reseller account and a 
                                    ClientExec support & billing setup – in case that you like to have your personal reseller hosting business.
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/network-uptime-03.webp" className="w-60" alt="A 99.9% Network Uptime" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A 99.9% Network Uptime</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> At Sheephostingbay, you’ll discover both a stable network system and extremely 
                                    good interaction with the world outside. We have assembled a custom inner network based upon enterprise–level Juniper routers and swithes. 
                                    Each of Sheephostingbay’s dedicated hosting servers features a 99.9% system availability warranty.
                                    </div>
                                </div>
                            </div>
                        </div> 
                      
                  </div>
              </div>
          </div>
      </div>
    </div>

    
    <Subscribe/>
    <Footer/>
  </div>
)

export default DedicatedPage
